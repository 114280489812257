import { MDBBtn, MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { MainLogo_img } from "../../assets";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiCall } from "../../utils";


export default function HomePage () {
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchPageData();
    },[])
    const fetchPageData = () => {
        setLoading(true);
        axios.get(ApiCall.categoryList)
        .then(res => {
            setLoading(false);
            console.log(res.data);
            const response = res.data.review_categories;
            setCategoryData(response);
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        })
    }
    const handleClick = (e) => {
        const data = {
            provider : e
        }
        navigate("/form", {state : data});
    }
    return(
        <MDBContainer  fluid
        className="d-flex align-items-center justify-content-center bg-image"
        style={{
          backgroundImage:
            "url(https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp)",
        }}  >
            <div className="mask gradient-custom-3"></div>
            <MDBCard className="ms-2 me-2 mt-5 mb-5" style={{ maxWidth: "900px" }} >
                <MDBCardBody className="px-5"  >
                <div style={{marginBottom: 40, paddingBottom: 20, display:"flex", alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderBottomColor: "black", borderBottomStyle: "dashed"}} >
         <img src={MainLogo_img} style={{maxWidth: 150}} className='img-fluid ' alt='logo' />
            </div>
            {loading === true ? null : categoryData.length === 0 ? null :categoryData.map((item, index) => {
                const {name, color, id} = item;
                return(
            <div style={{marginBottom: 20}} key={index} >
                <MDBBtn color={color} style={{width: "100%"}} onClick={() => handleClick(id)} >{name}</MDBBtn>
            </div>
                )
            })}
                </MDBCardBody>
            </MDBCard>

        </MDBContainer>
    )
}