import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { FormPage, HomePage, SplashPage } from "../pages";



export const RouterConf = () => {
    return(
        <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/form" element={<FormPage/>} />
            <Route path="/success" element={<SplashPage/>} />
        </Routes>
    )
}