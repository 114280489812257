import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";


export default function SplashPage () {
    const height = window.innerHeight;
    const navigate = useNavigate();
    return(
        <div style={{height: height, width: "100%", backgroundColor: "#80CBC4", display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: 20, paddingRight: 20}} >
            <div style={{padding: 20, backgroundColor: "white", borderRadius: 20, maxWidth: 500, display: "flex", flexDirection: "column", alignItems: "center"}} >
                <MDBIcon icon="check-circle" color="success" size="4x" />
                <p style={{marginTop: 20, marginBottom: 20, textAlign: "center"}} >Terimakasih sudah memberikan kami feed back !</p>
                <div>
                    <MDBBtn size="lg" color="info" onClick={() => navigate("/")} >
                        Submit Again
                    </MDBBtn>
                </div>
            </div>
        </div>
    )
}