

const develUri = `https://digicube.cloud/api`;

const productionUri = ``;

const currentUri = develUri;

export const ApiCall = {
    categoryList : `${currentUri}/review-category/lists`,
    createReview : `${currentUri}/review/create-new`
}