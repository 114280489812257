import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBBtnGroup,
  MDBRadio,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { MainLogo_img } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiCall } from "../../utils";

export default function FormPage() {
    const current = new Date();
    const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
    const location = useLocation();
    const {provider} = location.state;
    const navigate = useNavigate();
    // console.log("ini data : ",provider);
    const [frontDeskValue, setFrontDeskValue] = useState(0);
    const [frontDeskTitle, setFrontDeskTitle] = useState('');
    const [foodValue, setFoodValue] = useState(0);
    const [foodTitle, setFoodTitle] = useState('');
    const [kamarValue, setKamarValue] = useState(0);
    const [kamarTitle, setKamarTitle] = useState('');
    const [fasilitasKamarValue, setFasilitasKamarValue] = useState(0);
    const [fasilitasKamarTitle, setFasilitasKamarTitle] = useState('');
    const [conciergeValue, setConciergeValue] = useState(0);
    const [conciergeTitle, setConciergeTitle] = useState('');
    const [kebugaranValue, setKebugaranValue] = useState(0);
    const [kebugaranTitle, setKebugaranTitle] = useState('');
    const [layananValue, setLayananValue] = useState(0);
    const [layananTitle, setLayananTitle] = useState('');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');

    const answearUtils = [
        {
            id : 1,
            title: "Buruk",
            value: 0    
        },
        {
            id : 2,
            title: "Tidak Tahu",
            value: 1    
        },
        {
            id : 3,
            title: "Cukup Baik",
            value: 2    
        },
        {
            id : 4,
            title: "Baik",
            value: 3    
        },
        {
            id : 5,
            title: "Perfect",
            value: 4    
        },
    ]

    const handleChooseFrontDesk = (e) => {
        setFrontDeskValue(e.value);
        setFrontDeskTitle(e.title);
    }
    const handleChooseFood = (e) => {
        setFoodValue(e.value);
        setFoodTitle(e.title);
    }
    const handleChooseKebersihan = (e) => {
        setKamarValue(e.value);
        setKamarTitle(e.title);
    }
    const handleChooseFasilitasKamar = (e) => {
        setFasilitasKamarValue(e.value);
        setFasilitasKamarTitle(e.title);
    }
    const handleChooseConcierge = (e) => {
        setConciergeValue(e.value);
        setConciergeTitle(e.title);
    }
    const handleChooseKebugaran = (e) => {
        setKebugaranValue(e.value);
        setKebugaranTitle(e.title);
    }
    const handleChooseLayanan = (e) => {
        setLayananValue(e.value);
        setLayananTitle(e.title);
    }

    
    
    const handleSubmitForm = () => {
        if(email === ''){
           return null
        } else {
            const countTotal = frontDeskValue + foodValue + kamarValue + fasilitasKamarValue + conciergeValue + kebugaranValue + layananValue ;
            const data = {
                front_desk : frontDeskValue,
                restaurant : foodValue,
            kebersihan_kamar : kamarValue,
            fasilitas_kamar : fasilitasKamarValue,
            concierge : conciergeValue,
            pusat_kebugaran : kebugaranValue,
            layanan_kamar : layananValue,
            message : note,
            total : countTotal,
            category : provider,
            email :  email,
            hotel_id : 1,
            review_date : date
        }
        axios.post(ApiCall.createReview, data)
        .then(res => {
            console.log(res.data);
            navigate("/success");
        })
        .catch(err => {
            console.log(err);
        })
    }
    }

  return (
    <MDBContainer
      fluid
      className="d-flex align-items-center justify-content-center bg-image"
      style={{
        backgroundImage:
          "url(https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp)",
      }}
    >
      <div className="mask gradient-custom-3"></div>
      <MDBCard className="ms-2 me-2 mt-5 mb-5" style={{ maxWidth: "900px" }}>
      
        <MDBCardBody className="px-5">
            <div style={{marginBottom: 20, paddingBottom: 20, display:"flex", alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderBottomColor: "black", borderBottomStyle: "dashed"}} >
         <img src={MainLogo_img} style={{maxWidth: 150}} className='img-fluid ' alt='logo' />
            </div>
          {/* <h4 className="text-center mb-5">Form Kepuasan Pengunjung</h4> */}
          {/* frontdesk */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='user-tie' className='me-2 text-dark' size="2x" />Apakah anda puas dengan pelayanan dari Front Desk / Front Office kami ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={frontDeskTitle === ''? 'light' : 'warning'} >{frontDeskTitle === '' ? "Pilih Jawaban" : frontDeskTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link 
                        childTag='button'  onClick={() => handleChooseFrontDesk(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>

          {/* F & B */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='cocktail' className='me-2 text-dark' size="2x" />Bagaimana dengan makanan yang kami sediakan apakah anda puas dengan hidangan kami ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={foodTitle === ''? 'light' : 'warning'} >{foodTitle === '' ? "Pilih Jawaban" : foodTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link childTag='button'  onClick={() => handleChooseFood(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>

          {/* Kebersihan kamar */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='bed' className='me-2 text-dark' size="2x" />Bagaimana dengan kebersihan kamar kami apakah anda merasa puas dengan kebersihan kamar kami ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={kamarTitle === ''? 'light' : 'warning'} >{kamarTitle === '' ? "Pilih Jawaban" : kamarTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link childTag='button'  onClick={() => handleChooseKebersihan(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>

          {/* Fasilitas Kamar */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='hot-tub' className='me-2 text-dark' size="2x" />Bagaimana dengan fasilitas kamar kami, apakah anda puas dengan fasilitas kamar kami ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={fasilitasKamarTitle === ''? 'light' : 'warning'} >{fasilitasKamarTitle === '' ? "Pilih Jawaban" : fasilitasKamarTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link  childTag='button' onClick={() => handleChooseFasilitasKamar(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>

          {/* Concierge */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='user-lock' className='me-2 text-dark' size="2x" />Bagaimana dengan layanan pramutamu kami, apakah cukup membuat anda puas ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={conciergeTitle === ''? 'light' : 'warning'} >{conciergeTitle === '' ? "Pilih Jawaban" : conciergeTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link childTag='button' onClick={() => handleChooseConcierge(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>
        
          {/* Kebugaran */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='swimming-pool' className='me-2 text-dark' size="2x" />Bagaimana dengan fasilitas dan layanan kebugaran kami apakah kamu menikmatinya dengan baik ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={kebugaranTitle === ''? 'light' : 'warning'} >{kebugaranTitle === '' ? "Pilih Jawaban" : kebugaranTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link childTag='button' onClick={() => handleChooseKebugaran(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>
          {/* Layanan */}

          <div style={{marginBottom: 30}} >
         <div style={{marginBottom: 20}} >
            <MDBIcon icon='concierge-bell' className='me-2 text-dark' size="2x" />Bagaimana layanan kamar yang kami sediakan, apakah anda menikmatinya ?
         </div>
         <MDBDropdown>
            <MDBDropdownToggle color={layananTitle === ''? 'light' : 'warning'} >{layananTitle === '' ? "Pilih Jawaban" : layananTitle}</MDBDropdownToggle>
            <MDBDropdownMenu>
                {answearUtils.map((item, index) => {
                    const {title, value} = item;
                    return(
                        <MDBDropdownItem key={index} link childTag='button' onClick={() => handleChooseLayanan(item) } >{title}</MDBDropdownItem>
                    )
                })}
            </MDBDropdownMenu>
         </MDBDropdown>
          </div>
          <div style={{marginBottom: 30}} >
          <div style={{marginBottom: 20}} >
            <MDBIcon icon='inbox' className='me-2 text-dark' size="2x" />Pesan dan saran
         </div>
                <MDBTextArea label='Message' id='textAreaExample' rows={4} value={note} onChange={(e) => setNote(e.target.value)}  />
          </div>
          <div style={{marginBottom: 30}} >
          <div style={{marginBottom: 20}} >
            <MDBIcon icon='envelope' className='me-2 text-dark' size="2x" />Email
         </div>
                <MDBInput label='Masukan Email Anda' id='typeEmail' type='email' value={email} onChange={(e) => setEmail(e.target.value)}  />
          </div>
        
          <MDBRow>
          <MDBCol>
          <MDBBtn className="mb-4 w-100 gradient-custom-4" size="lg">
            Back
          </MDBBtn>
          </MDBCol>
          <MDBCol>
          <MDBBtn className="mb-4 w-100 gradient-custom-4" size="lg" onClick={handleSubmitForm} >
            Submit
          </MDBBtn>
          </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

// export default App;
